<template>
  <div>
    <el-header class="reaHead">
      <div class="index-one" @click="homeClick">
        <el-image :src="require('@/assets/logo.png')" alt=""></el-image>
        <div class="index-title">会务管理系统</div>
      </div>
    </el-header>
    <div style="margin-top: 20px">
      <div class="topTitle">
        找回登录密码
      </div>
      <div style="width: 40%;height: 1px;border-top: 1.5px solid #ccc;margin: 0 auto;margin-bottom: 10px;margin-top: 10px;"></div>
    </div>
    <div class="forget-form">
      <el-form :model="forgetYzmlForm" ref="forgetYzmlForm" style="margin: auto;" :rules="forgetYzmrules" hide-required-asterisk>
        <el-form-item prop="phone" label="手机号：" class="yzm">
          <el-input v-model="forgetYzmlForm.phone" type="text" class="yzm-phone" auto-complete="off" placeholder="手机号" clearable></el-input>
        </el-form-item>
        <el-form-item prop="yzm" class="yzm" label="验证码：">
          <el-input v-model="forgetYzmlForm.yzm" type="password" placeholder="验证码" class="login-yzm"></el-input>
          <el-button :disabled="isClick" :class="[isClick ? 'login-isgetyzm' : 'login-getyzm']" class="" @click="sms">{{ yzmcontent }}</el-button>
        </el-form-item>
        <el-button class="next-button" @click="nextStep">下一步</el-button>
      </el-form>
    </div>
  </div>
</template>
<script>
import _qs from "qs";
export default {
  data() {
    var checkAge = (rule, value, callback) => {
      if (!value && String(value) !== "0") {
        return callback(new Error("不能为空"));
      }
      setTimeout(() => {
        if (!/^1[3|4|5|7|8][0-9]\d{8}$/.test(value)) {
          callback(new Error("请输入正确的手机号"));
        } else {
          callback();
        }
      }, 100);
    };
    return {
      isClick: false,
      yzmcontent: "获取验证码",
      forgetYzmrules: {
        phone: [
          { required: true, validator: checkAge, trigger: "blur" },
        ],
      },
      // 验证码登录
      forgetYzmlForm: {
        phone: "",
        yzm: "",
      },
    };
  },
  created() {
  },
  methods: {
    homeClick(){
      this.$router.push("/login");
    },
    // 点击获取验证码
    async sms() {
      this.$refs.forgetYzmlForm.validate(async (valid) => {
        if (valid) {
          let snum = 60;
          this.timer = setInterval(() => {
            snum--;
            this.yzmcontent = "重新获取(" + snum + "s)";
            this.isClick = true;
            if (snum === 0) {
              clearInterval(this.timer);
              this.yzmcontent = "获取验证码";
              this.isClick = false;
              return;
            }
          }, 1000);
          let data = _qs.stringify({
            phone: this.forgetYzmlForm.phone,
          });
          let { data: res } = await this.$axios.forgetSms(data);
          // console.log(res);
          if (res.code == 200) {
            this.$message({
              message: "获取验证码成功",
              type: "success",
            });
          } else if (res.code == 401) {
            this.$router.push("/login");
          } else if (res.code == 500) {
            this.$message({
              message: "获取验证码失败",
              type: "error",
            });
          }
        } else {
          console.log("error submit!");
          return false;
        }
      });
    },
    // 验证码登录
    async nextStep() {
      if (this.forgetYzmlForm.yzm == "") {
        this.$message.error("请输入验证码");
        return;
      }
      let data = _qs.stringify({
        phone: this.forgetYzmlForm.phone,
        code: this.forgetYzmlForm.yzm
      });
      let res = await this.$axios.verifyForgetCode(data);
      if (res.data.code == 200) {
        window.sessionStorage.setItem("forgetPhone", this.forgetYzmlForm.phone);
        this.$router.push({ path: "/confirmPwd" });
        this.$message({
          message: "验证成功！",
          type: "success",
        });
      } else if (res.data.code == 500) {
        this.$message.error("验证码错误！");
        // this.yzmcontent = "获取验证码";
        // clearInterval(this.timer);
        this.forgetYzmlForm.yzm = "";
        // this.isClick= false;
      }
    },
  },
};
</script>

<style lang="less" scoped>

.topTitle{
  width: 40%;
  margin: 0 auto;
  text-align: center;
  font-weight: 700;
  font-size: 18px;
}

.yzm {
  width: 100%;
  display: flex;
  flex-direction: row;
  .login-yzm {
    width: 60%;
  }
  .login-getyzm {
    width: 40%;
    background: #0380dd;
    color: white;
  }
  .login-isgetyzm {
    width: 40%;
    background: #ccc;
    color: white;
  }
  .yzm-phone{
    width: 143%;
  }
}
.next-button {
  width: 100%;
  position: relative;
  background-image: linear-gradient(to right, #00c3ff, #00abff, #0095ff);
  color: white;
  font-weight: bolder;
}
.forget-form {
  width: 38%;
  height: 300px;
  background: white;
  display: flex;
  padding: 10px 20px;
  box-shadow: 0 0 16px #ccc;
  //border-radius: 20px 0 20px 20px;
  margin: 0 auto;
  margin-top: 20px;
  text-align: center;
}
/deep/ .reaHead {
  position: relative;
  width: 100%;
  height: 80px !important;
  display: flex;
  align-items: center;
  //background: rgba(0, 0, 0, 0.6);
  background:#1b63c1 !important;
  color: white;
  justify-content: space-between;
  .index-title {
    font-size: 30px;
  }
  .index-one {
    display: flex;
    position: relative;
    align-items: center;
  }
  .index-one:hover {
    cursor: pointer;
  }

  .el-image__inner {
    width: 55%;
  }
  /deep/ .el-input--prefix .el-input__inner {
    border-radius: 20px;
  }
}
</style>
